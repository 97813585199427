import axios from "axios";
import TokenService from "./token.service";

const OPENID_URL = 'https://spf.platform-auth.leanova.fr/auth/realms/master/protocol/openid-connect/token';

const instance = axios.create({
  baseURL: 'https://spf.platform-api.leanova.fr/api',
  headers: {
    'content-type': 'application/x-www-form-urlencoded'
  },
});
instance.interceptors.request.use(
  (config) => {
      config.headers["Authorization"] = 'Bearer ' + TokenService.getToken();

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== "/auth/signin" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = false;
        try {
          let params = new URLSearchParams();

          params.append('refresh_token', TokenService.getRefreshToken());
          params.append('client_id', 'frontend');
          params.append('grant_type', 'refresh_token');
          params.append('client_secret', '');
          params.append('scope', '');
          const rs = await instance.post(OPENID_URL, params);
          TokenService.setToken( rs.data.access_token);
          TokenService.setRefreshToken( rs.data.refresh_token);
          return instance(originalConfig);
        } catch (_error) {
          TokenService.removeRefreshToken();
          TokenService.removeToken();
          window.location = '/login';
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  }
);
export default instance;
