import { useState } from 'react';
import TokenService from '../services/token.service'

export default function useToken() {
    const getToken = () => {
        const userToken = JSON.parse(localStorage.getItem("token"));
        return userToken
    };

    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
        TokenService.setToken(userToken);
        setToken(userToken);
    };

    return {
        setToken: saveToken,
        token
    }
}
