class TokenService {
  updateLocalAccessToken(token) {
    localStorage.setItem("token", JSON.stringify(token));
  }
  getToken() {
    return JSON.parse(localStorage.getItem("token"));
  }
  setToken(token) {
    localStorage.setItem("token", JSON.stringify(token));
  }
  removeToken() {
    localStorage.removeItem("token");
  }
  getRefreshToken() {
    return JSON.parse(localStorage.getItem("refresh_token"));
  }
  setRefreshToken(token) {
    localStorage.setItem("refresh_token", JSON.stringify(token));
  }
  removeRefreshToken() {
    localStorage.removeItem("refresh_token");
  }
}
export default new TokenService();
