import React, { lazy } from 'react';
import { useRoutes } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useSelector } from 'react-redux';
import RTL from './layouts/full-layout/customizer/RTL';
import ThemeSettings from './layouts/full-layout/customizer/ThemeSettings';
//import Router from './routes/Router';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Navigate } from 'react-router-dom';
import Loadable from './layouts/full-layout/loadable/Loadable';
import TokenService from './services/token.service';
import useToken from './hooks/useToken';
import { AuthContext } from './contexts/AuthContext';
import AuthService from './services/auth.service'

const App = () => {
  const { token, setToken } = useToken();
  /* ***Layouts**** */
  const FullLayout = Loadable(lazy(() => import('./layouts/full-layout/FullLayout')));
  const BlankLayout = Loadable(lazy(() => import('./layouts/blank-layout/BlankLayout')));
  /* ***End Layouts**** */

  const Error = Loadable(lazy(() => import('./views/authentication/Error')));

  /* ****Pages***** */
  const Dashboard = Loadable(lazy(() => import('./views/dashboard/Dashboard')));
  const Categories = Loadable(lazy(() => import('./views/dashboard/Categories')));
  const CategoryBooks = Loadable(lazy(() => import('./views/dashboard/CategoryBooks')));
  const Favorites = Loadable(lazy(() => import('./views/dashboard/Favorites')));
  const Library = Loadable(lazy(() => import('./views/dashboard/Library')));
  const Book = Loadable(lazy(() => import('./views/dashboard/books/Detail')));
  const ReadBook = Loadable(lazy(() => import('./views/dashboard/books/ReadBook')));
  const Login = Loadable(lazy(() => import('./views/authentication/Login')));

  const theme = ThemeSettings();
  const customizer = useSelector((state) => state.CustomizerReducer);

  const initialLoginState = {
    userToken: null,
  };

  const loginReducer = (prevState, action) => {
    switch (action.type) {
      case 'RETRIEVE_TOKEN':
        return {
          ...prevState,
          userToken: action.token,
        };
      case 'LOGIN':
        return {
          ...prevState,
          userToken: action.token,
        };
      case 'LOGOUT':
        return {
          ...prevState,
          userToken: null,
        };
    }
  };

  const [loginState, dispatch] = React.useReducer(loginReducer, initialLoginState);

  const authContext = React.useMemo(
    () => ({
      signIn: (token) => {
        try {
          TokenService.setToken(token);
          AuthService.getCurrentUser()
          dispatch({ type: 'LOGIN', token: token });
        } catch (error) {}
      },
      signOut: async () => {
        TokenService.removeToken();
        dispatch({ type: 'LOGOUT' });
      },
    }),
    [],
  );

  React.useMemo(async () => {
    const authDataString = await TokenService.getToken();
    try {
      if (authDataString) {
        dispatch({ type: 'RETRIEVE_TOKEN', token: authDataString });
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  const DashboardRouting = [
    {
      path: '/auth',
      element: <BlankLayout />,
      children: [
        { path: '404', element: <Error /> },
        { path: '*', element: <Navigate to="/auth/404" /> },
      ],
    },
    {
      path: '/',
      element: <FullLayout setToken={setToken} />,
      children: [
        { path: '/', exact: true, element: <Navigate to="/dashboard" /> },
        { path: '/dashboard', exact: true, element: <Dashboard /> },
        { path: '/dashboard/categories', exact: true, element: <Categories /> },
        { path: '/dashboard/categories/:category', exact: true, element: <CategoryBooks /> },
        { path: '/dashboard/saved', exact: true, element: <Favorites /> },
        { path: '/dashboard/library', exact: true, element: <Library /> },
        { path: '/dashboard/books/detail/:book', exact: true, element: <Book /> },
        { path: '/dashboard/books/read/:book', exact: true, element: <ReadBook /> },
        { path: '404', exact: true, element: <Error /> },
        { path: '*', element: <Navigate to="/auth/404" /> },
      ],
    },
  ];

  const login = [
    {
      path: '/auth',
      element: <BlankLayout />,
      children: [
        { path: '404', element: <Error /> },
        { path: '*', element: <Navigate to="/auth/404" /> },
      ],
    },
    {
      path: '/',
      element: <BlankLayout />,
      children: [
        { path: '/', element: <Navigate to="/login" /> },
        { path: '/login', exact: true, element: <Login setToken={setToken} /> },
        { path: '404', exact: true, element: <Error /> },
        { path: '*', element: <Navigate to="/auth/404" /> },
      ],
    },
  ];
  const routing = useRoutes(DashboardRouting);
  const loginRouting = useRoutes(login);
  return (
    <AuthContext.Provider value={authContext}>
      <ThemeProvider theme={theme}>
        <RTL direction={customizer.activeDir}>
          <CssBaseline />
          {loginState.userToken ? routing : loginRouting}
        </RTL>
      </ThemeProvider>
    </AuthContext.Provider>
  );
};

export default App;
