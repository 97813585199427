import api from './api';
import TokenService from './token.service' ;
const OPENID_URL = 'https://spf.platform-auth.leanova.fr/auth/realms/master/protocol/openid-connect/token';


class AuthService {
  login(username, password) {
    let params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);
    params.append('client_id', 'frontend');
    params.append('grant_type', 'password');
    params.append('client_secret', '');
    params.append('scope', '');
    return api.post(OPENID_URL, params
      )
      .then(response => {
        if (response.data.access_token) {
          TokenService.setToken(response.data.access_token);
          TokenService.setRefreshToken(response.data.refresh_token);
        }
        return response.data;
      });
  }

  refresh(token) {
    let params = new URLSearchParams();
    params.append('refresh_token', token);
    params.append('client_id', 'frontend');
    params.append('grant_type', 'refresh_token');
    params.append('client_secret', '');
    params.append('scope', '');
    return api.post("/auth/token", params)
      .then(response => {
        if (response.data.refresh_token) {
          TokenService.setToken(response.data.refresh_token);
        }
        return response.data;
      });
  }

  logout() {
    TokenService.removeToken();
    TokenService.removeRefreshToken();
  }

  getCurrentUser() {
    return api.get("/me")
      .then(response => {
        console.log(response)
        if(response.data.devices)
          localStorage.setItem("device_id", JSON.stringify(response.data.devices[0].id));
          localStorage.setItem("device_name", JSON.stringify(response.data.devices[0].name));
        return response.data;
      });
  }
}
export default new AuthService();
