import React, {useEffect} from "react";

const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
    return (
        <AuthContext.Provider>
            {children}
        </AuthContext.Provider>
    );
};

const AuthConsumer  = AuthContext.Consumer;
export { AuthContext, AuthProvider, AuthConsumer};
